/**
 * A component that displays a label and a corresponding value.
 * If a link is provided, the value will be rendered as a clickable link.
 * If a clickHandler is provided, it will create a button against that and will call the clickHandler on click.
 * If the `emptyField` prop is true, the component will have a different styling to indicate an empty field.
 */

import { Typography } from '@vartanainc/design-system';
import { Link } from 'react-router-dom';

interface LabelValueFieldProps {
  label: string;
  value: string;
  link?: string;
  emptyField?: boolean;
  onClickHandler?: () => void;
  disabled?: boolean;
}

const LabelValueField: React.FC<LabelValueFieldProps> = ({
  label,
  value,
  link,
  emptyField,
  onClickHandler,
  disabled,
}) => {
  const getContainerClassName = (): string => {
    const baseClassName = 'flex flex-col flex-1 gap-1';
    return emptyField
      ? `${baseClassName} empty-field`
      : `${baseClassName} populated-field`;
  };

  const renderContent = (): JSX.Element => {
    const baseClassName = 'break-all vp-text-link-semibold text-vartana-blue-100';
    if (link) {
      return (
        <Link to={link} className={`${baseClassName} underline`}>
          {value}
        </Link>
      );
    }

    if (onClickHandler) {
      return (
        <button
          type="button"
          onClick={onClickHandler}
          className={`${baseClassName} text-left ${
            disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
          }`}
          disabled={disabled}
        >
          {value}
        </button>
      );
    }

    return (
      <Typography
        variant="paragraph14"
        color="color-black-100"
        className="break-all whitespace-pre-line"
      >
        {value}
      </Typography>
    );
  };

  return (
    <div className={getContainerClassName()}>
      <p className="break-all vartana-p-small-bold text-vartana-gray-50">{label}</p>
      {renderContent()}
    </div>
  );
};

export default LabelValueField;
