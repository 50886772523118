import { Typography } from '@vartanainc/design-system';
import { ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { get } from 'lodash';
import { useMutation, useReactiveVar } from '@apollo/client';
import { WidgetMetaContext } from '../../../../context/WidgetMetaContext/WidgetMetaContext';
import { WidgetContext } from '../../../../context/WidgetContext';
import { formatAmount, handleDownload, renderGuaranteeStatus } from '../widgetUtils';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import { CurrencyPill } from '../../../../components/Pill/CurrencyPill';
import { sessionVar } from '../../../../graphql/cache';
import { GENERATE_PAYMENT_PLAN } from '../../../../graphql/queries/customer';
import { WIDGET_WIDTH_VARIANTS } from '../../../../constants/common.constants';

interface CreditInformationProps {
  companyNumber: string;
}

const CreditInformation = ({ companyNumber }: CreditInformationProps): ReactElement => {
  const widgetContext = useContext(WidgetContext);
  const metaContext = useContext(WidgetMetaContext);
  const activeTab: string = get(metaContext, 'activeTab', '-');
  const isCustomSchedule = get(metaContext, 'meta.isCustomSchedule', false);
  const [generatePaymentPlan] = useMutation(GENERATE_PAYMENT_PLAN);
  const [isPaymentPlanLoading, setIsPaymentPlanLoading] = useState<boolean>(false);

  const sessionData = useReactiveVar(sessionVar);
  const showCurrency = get(
    sessionData,
    'session.user.company.product.multipleCountriesEnabled',
    false
  );
  const currency = get(metaContext, 'meta.calculator.currency', '');

  const widthVariant = useMemo(() => {
    return get(widgetContext, 'widthVariant', '');
  }, [widgetContext]);

  const callCustomSchedulePreview = useCallback(async () => {
    if (isPaymentPlanLoading) {
      return;
    }
    setIsPaymentPlanLoading(true);
    const proposedPayments = get(metaContext, 'meta.calculator.proposedPayments');
    const { data } = await generatePaymentPlan({
      variables: {
        customerNumber: companyNumber,
        opportunityAmount: formatAmount(get(proposedPayments, 'amount', '')),
        billingFrequency: get(proposedPayments, 'billing_frequency', ''),
        term: get(metaContext, 'meta.calculator.terms[0]', 0),
        blindDiscount: parseFloat(get(metaContext, 'meta.calculator.subsidy', '')),
        paymentTerm: get(metaContext, 'meta.calculator.netTerms[0]', 0),
        isDollar: false,
        spiffRate: parseFloat(get(proposedPayments, 'spiff_rate', '0')),
      },
    });

    const proposal = get(data, 'generatePaymentPlan');
    const fileName = `${companyNumber}-payment-plan.pdf`;
    handleDownload(proposal, fileName);
    setIsPaymentPlanLoading(false);
  }, [isPaymentPlanLoading, metaContext, generatePaymentPlan, companyNumber]);

  const renderCustomSchedulePaymentPlan = (label: string): JSX.Element => {
    return (
      <>
        <div
          role="button"
          onClick={callCustomSchedulePreview}
          tabIndex={isPaymentPlanLoading ? -1 : 0} // Prevent keyboard interaction
          aria-disabled={isPaymentPlanLoading} // Improve accessibility
          className={`text-vartana-blue-100 border-none ${
            isPaymentPlanLoading ? 'opacity-50 cursor-no-drop' : 'cursor-pointer'
          }`}
        >
          {label}
        </div>
      </>
    );
  };

  const renderCustomerIsValidUntil = (): ReactElement => {
    const appraisalValidUntil = get(
      metaContext,
      'meta.creditDetails.appraisalValidUntil',
      ''
    );
    const pricingValidUntil = get(
      metaContext,
      'meta.creditDetails.pricingValidUntil',
      ''
    );
    const customerValidUntil = `${appraisalValidUntil} • ${pricingValidUntil}`;

    if ([WIDGET_WIDTH_VARIANTS.sm, WIDGET_WIDTH_VARIANTS.md].includes(widthVariant)) {
      return (
        <>
          <Typography
            variant="paragraph10"
            color="color-black-100"
            className="credit-details"
          >
            {get(metaContext, 'meta.creditDetails.appraisalValidUntil', '')}
          </Typography>
          <Typography
            variant="paragraph10"
            color="color-black-100"
            className="credit-details"
          >
            {get(metaContext, 'meta.creditDetails.pricingValidUntil', '')}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          variant="paragraph10"
          color="color-black-100"
          className="credit-details"
        >
          {customerValidUntil}
        </Typography>
      </>
    );
  };

  return (
    <div className="flex flex-1 flex-col justify-center items-center self-stretch px-0 gap-4 mb-2">
      <div className="flex flex-col justify-end items-center gap-2 pr-0 pt-[1.75rem]">
        <div className="flex gap-2 justify-center items-center">
          <Typography variant="paragraph14" bold className="available-amount">
            Available amount
          </Typography>
          {showCurrency && <CurrencyPill compactPill currency={currency} />}
        </div>
        <Typography variant="heading24" color="color-blue-180">
          {get(metaContext, `${activeTab}.creditInfo.availableCredit`, '')}
        </Typography>
      </div>
      <div className="flex flex-col justify-center items-start gap-2 rounded-lg border border-vartana-gray-80 p-4 max-w-[18rem] ">
        {Object.keys(get(metaContext, `${activeTab}.creditInfo`, {})).map(
          (key) =>
            key !== 'availableCredit' && (
              <div className="flex flex-row items-left justify-start gap-2" key={key}>
                <SvgIcon
                  name="check"
                  width="1rem"
                  height="1rem"
                  fill="#21438B"
                  className="justify-center"
                />
                <Typography variant="paragraph14" className="text-left credit-info">
                  {isCustomSchedule && key === 'options'
                    ? renderCustomSchedulePaymentPlan(
                        get(metaContext, `${activeTab}.creditInfo.${key}`, '')
                      )
                    : get(metaContext, `${activeTab}.creditInfo.${key}`, '')}
                </Typography>
              </div>
            )
        )}
      </div>
      <div className="flex flex-col items-center gap-[0.437rem]">
        <Typography
          variant="paragraph10"
          color="color-black-100"
          className="credit-details"
        >
          {get(metaContext, 'meta.creditDetails.minimumAmount', '')}
        </Typography>
        {renderCustomerIsValidUntil()}
        {renderGuaranteeStatus(get(metaContext, 'meta.calculator'))}
      </div>
    </div>
  );
};

export default CreditInformation;
